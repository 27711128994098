export default [
	{ 
		icon: () => import('./icon/school.svg?inline'),
		number: '600+',
		text: 'škôl',
		color: 'primary',
		backgroundColor: 'blue-light',
		image: './img/polygon1.png'
	},
	{ 
		icon: () => import('./icon/teacher.svg?inline'),
		number: '7500+',
		text: 'Registrovaných učiteľov',
		color: 'orange',
		backgroundColor: 'orange-light',
		image: './img/polygon2.png'
	},
	{ 
		icon: () => import('./icon/video.svg?inline'),
		number: '100+',
		text: 'Video kurzov',
		color: 'secondary',
		backgroundColor: 'red-light',
		image: './img/polygon3.png'
	}
]
