<template>
	<b-container>
		<b-row class="a-section-title">
			<b-col>
				<h2 class="text-center">
					<span class="w-color-black">{{ $t('Toto je zmudri') }}</span>
					<span class="w-color-primary z-fancy-underline">
						{{ $t('v číslach') }}<svg-underline-special-icon />
						<img src="./img/numbers.png" class="a-small-logo" alt="">
					</span>
				</h2>
			</b-col>
		</b-row>
		<b-row align-h="center">
			<b-col v-for="(stat, i) in stats" :key="i" xl="4" md="6" class="card-number-wrapper">
				<div :class="`card-number z-border-radius-20 w-background-${stat.backgroundColor}`">
					<div class="z-icon-wrapper -large w-background-white mx-4">
						<component :is="stat.icon"></component>
					</div>
					<div>
						<h3>{{ stat.number }}</h3>
						<p class="font-weight-bold w-color-blue-dark">{{ stat.text }}</p>
					</div>
				</div>
				<svg-curved-triangle-icon :class="`w-color-${stat.color} triangle d-none d-xl-block`" />
				<svg-curved-triangle-border-icon :class="`w-color-${stat.color} triangle-border d-none d-xl-block`" />
				<!-- <template v-if="i == 0">
					<div class="triangle-border -a"></div>
					<div class="triangle-border -b"></div>
					<div class="triangle-border -c"></div>
					<div class="triangle-border-rounded-corner"></div>
				</template> -->
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import stats from './stats.js'

export default {

	components: {
		'svg-underline-special-icon': () => import('./icon/underline-special.svg?inline'),
		'svg-curved-triangle-icon': () => import('@/plugins/appzmudri/_theme/icon/curved-triangle.svg?inline'),
		'svg-curved-triangle-border-icon': () => import('@/plugins/appzmudri/_theme/icon/curved-triangle-border.svg?inline')
	},

	data() {
		return {
			stats
		}
	}
}
</script>
<style lang="sass" scoped>
.card-number-wrapper
	position: relative

	.triangle-border
		position: absolute
		top: 50%
		width: 100%

	.triangle
		position: absolute
		top: 50%

	.card-number
		p
			font-family: 'Poppins'
		&::after
			position: absolute
			width: 4px
			content: ''
			height: 100%
			background-color: var(--background-color)
			z-index: 10


	&:nth-child(1)
		.triangle-border
			transform: translateY(-52%)
			height: 480px
			right: 60px

		.triangle
			transform: translateY(220%)
			left: 125px

		.card-number
			&::after
				right: 70px

	&:nth-child(2)
		.triangle-border
			transform: translateY(-46%) rotate(-60deg)
			transform-origin: center
			height: 490px
			right: 65px

		.triangle
			transform: translateY(-280%) rotate(60deg)
			right: 100px

		.card-number
			&::after
				left: 26px

	&:nth-child(3)
		.triangle-border
			transform: translateY(-52%)
			height: 480px
			right: 5px

		.triangle
			transform: translateY(-340%) translateX(-50%)
			left: 45%

		.card-number
			&::after
				right: 15px

.card-number
	position: relative
	max-width: calc(100vw - 2rem)
	height: 300px
	display: flex
	align-items: center
	overflow: hidden
	margin-bottom: 1rem

	@media only screen and (min-width: 1400px)
		max-width: 360px
	
	h3
		font-size: 80px

	p
		font-size: 28px
	
.z-fancy-underline
	svg
		width: 110%
		right: -5%

.card-number
	height: 240px
	h3
		font-size: 4rem
	p
		font-size: 1.5rem

.card-number-wrapper
	&:nth-child(1)
		.triangle-border
			height: 390px
			right: 50px

			@media only screen and (min-width: 1400px)
				right: 80px

		.triangle
			transform: translateY(160%)
			left: 110px

		.card-number
			&::after
				right: 56px

	&:nth-child(2)
		.triangle-border
			height: 408px
			right: 50px

			@media only screen and (min-width: 1400px)
				right: 80px

		.triangle
			transform: translateY(-245%) rotate(60deg)
			right: 80px

		.card-number
			&::after
				left: 20px

	&:nth-child(3)
		.triangle-border
			height: 380px
			right: 10px

			@media only screen and (min-width: 1400px)
				right: 40px

		.triangle
			transform: translateY(-285%) translateX(-50%)
			left: 50%

		.card-number
			&::after
				right: 20px

@media only screen and (min-width: 1200px)
	.card-number-wrapper
		padding-top: 7rem
		padding-bottom: 7rem

@media only screen and (max-width: 1199px)
	.card-number-wrapper
		.card-number
			&::after
				display: none
	
@media only screen and (max-width: 575px)
	.card-number
		height: 180px
		h3
			font-size: 3rem
		p
			font-size: 1.25rem
</style>
